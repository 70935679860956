import * as React from 'react'
import * as z from 'zod'
import { TranslationFn } from '@hooks/Translation/EnumTranslation'
import { MenuItem, MenuItemProps } from '@mui/material'
import { AllValue, AllValueSchema } from '@controls/types'

export type MenuItemEntry = Readonly<{
  label: string
  value: MenuItemProps['value']
}>

export type MenuItemEntryWithAllValue = Readonly<{
  label: string
  value: AllValue
}>

export const buildEmptyValue = (emptyValueLabel: string): MenuItemEntryWithAllValue => ({
  value: AllValueSchema.value,
  label: emptyValueLabel
})

export const buildEnumOptions = <T extends [string, ...Array<string>]>(
  enumSchema: z.ZodEnum<T>,
  translationFn: TranslationFn<T[number]>
): ReadonlyArray<MenuItemEntry> => enumSchema.options.map((value) => ({ value, label: translationFn(value) }))

export const buildEnumOptionsWithSameLabelAndValues = <T extends string | number>(
  values: ReadonlyArray<T>
): ReadonlyArray<MenuItemEntry> => values.map((value) => ({ value, label: value.toString() }))

export const renderMenuItems = (options: ReadonlyArray<MenuItemEntry>): ReadonlyArray<React.ReactElement> =>
  options.map((item, index) => (
    <MenuItem key={index} value={item.value}>
      {item.label}
    </MenuItem>
  ))
